<template>
  <div class="working-transport main-block">
    <div class="main-block__head">
      <h1 class="main-block__title">Работа с транспортом</h1>
    </div>
    <ul v-if="allCarsData" class="working__items main-block__box">
      <li v-for="index in 15" :key="index" class="working__item flex">
        <img class="working__img" src="@/assets/png/charecterist-img.png" alt="" />
        <div class="working__box flex">
          <div class="working__id">
            <p class="working__name">Азат Азатов</p>
            <p class="working__text">Водитель-экспедитор</p>
          </div>
          <div class="working__info">
            <div class="working__road flex m-0">
              <p class="working__road-text working__road_begin">KRG</p>
              <div class="working__road-block">
                <div class="working__road-box" style="width: 25%" />
                <div class="working__road-box-after" />
              </div>
              <p class="working__road-text">TSE</p>
            </div>
            <div class="flex-only">
              <img class="mr-1" src="@/assets/svg/working-icon.svg" alt="" />
              <p class="working__text">Пройдено 3450 км</p>
            </div>
          </div>
          <div>
            <span class="working_way_name">SATTI-276389888888</span>
          </div>
          <div>
            <span class="working_way_name">Karagandy-Astana</span>
          </div>
          <div class="working__date-box">
            <p class="working__text">Satti Logistics LTD</p>
            <label class="working__text" for="">10:39</label>
            <label class="working__date" for="">Ноябрь 18</label>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    allCarsData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.working__img {
  width: 5%;
  max-width: 30px;
  height: 30px;
  background: #83b1f4;
  border-radius: 5px;
  margin-right: 10px;
}
.working__box {
  width: 96%;
  padding-top: 15px;
}
.working__item:not(:last-child) .working__box {
  border-bottom: 1px solid #f2f2f8;
  padding-bottom: 9px;
  overflow: scroll;
}
.working__id {
  width: 17%;
}
.working__info {
  width: 20%;
}
.working__name {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 2px;
}
.working__text {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #707070;
  margin: 0;
}
.working__info-icon {
  margin: 2px 5px;
}
.working__date {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #000;
  margin: 0 0 0 5px;
}
.working__date-box {
  width: 10%;
  text-align: right;
}
.working__road-box {
  background: #28A745;
}
.working__road-box::before {
  background: #28A745;
  
}
.working__road-box::after {
  border-color: #28A745
}
.working__road_begin {
  color: #28A745;
}
.working_way_name{ 
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.main-block {
  font-family: sans-serif;
}
.working__items {
  overflow: scroll
}
</style>
